<template>
  <div>
    <!-- Sessions -->
    <b-row align-h="between">
      <b-col><h2>Sessions</h2></b-col>
    </b-row>
    <b-tabs pills>
      <b-tab v-for="sessionDate in Object.keys(sessions)" :key="sessionDate" :title="$date(sessionDate).format('DD MMM YYYY')">
        <b-row class="match-height">
          <b-col cols="12" md="6" xl="4" v-for="session in sessions[sessionDate]" :key="session.id">
            <agenda-card :data="session" :title="title" :description="description" :location="location" :customStyle="event.meta.style.agendaCard || ''" />
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
    <!-- Sessions End-->
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BLink,
  BImg,
  BCarousel,
  BCarouselSlide,
  BRow,
  BCol,
  BMedia,
  BTab,
  BTabs,
} from "bootstrap-vue";
import { $themeConfig } from "@themeConfig";
import AgendaCard from "@/views/pages/sessions/AgendaCard.vue";
import store from '@/store/index'

export default {
  metaInfo: {
    title: 'Sessions',
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BImg,
    BCarousel,
    BCarouselSlide,
    BRow,
    BCol,
    BMedia,
    BTabs,
    BTab,
    AgendaCard,
  },
  computed: {
    sessions() {
       return store.getters['sessions/allDated']
    },
    event() {
      return store.state.event.event
    }
  },
  watch: {
    async sessions() {
      return this.$store.getters['sessions/allDated']
    }
  },
  mounted() {
    // console.log('sessions', this.$store.state.sessions);
    // if (!this.$store.state.sessions.sessions || this.$store.state.sessions.sessions.length === 0) {
      this.$store.dispatch('sessions/getSessions', '')
    // } 
  },
  props: {
    title: {
      type: Boolean,
      default: () => false,
    },
    description: {
      type: Boolean,
      default: () => true,
    },
    location: {
      type: Boolean,
      default: () => true,
    }
  },
  setup() {
    // App Name
    const { appLogoImage } = $themeConfig.app;
    return {
      appLogoImage,
    };
  },
};
</script>

<style>
  
</style>
